import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import { Button, Card, Grid } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAuth } from '../providers/AuthProvider';
import { useApi } from '../providers/ServerApiProvider';

const AiChat: React.FC = () => {
  const { useBreakpoint } = Grid;
  const auth = useAuth();
  const serverApi = useApi();
  const screens = useBreakpoint();
  const { t } = useTranslation();

  const chatSrc = window.toolboxConfig.toolbox_expert_iframe_src;

  const [hidden, setHidden] = useState<boolean>(false);

  return (
    <div
      style={{
        minWidth: '400px',
        position: 'relative',
        backgroundColor: 'white',
        height: hidden && !(screens.sm && screens.md) ? 56 : 'auto',
      }}
    >
      {!(screens.sm && screens.md) && (
        <div
          style={{
            position: 'absolute',
            right: '58px',
            top: '12px',
          }}
        >
          <Button
            type="primary"
            shape="circle"
            icon={hidden ? <CaretDownOutlined /> : <CaretUpOutlined />}
            onClick={() => {
              setHidden(!hidden);
            }}
          ></Button>
        </div>
      )}
      {auth.isLoggedIn() ? (
        <iframe
          src={chatSrc}
          style={{
            zIndex: 100,
            width: '100%',
            height: '100%',
            minHeight: '420px',
            border: 0,
          }}
          allow="microphone"
        ></iframe>
      ) : (
        <div>
          <div
            style={{
              padding: '16px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              height: '56px',
              backgroundImage:
                'linear-gradient(to right, rgb(37, 99, 235), rgb(14, 165, 233))',
            }}
          >
            <div style={{ color: 'white', fontWeight: 600 }}>
              NextGIS Toolbox Expert
            </div>
          </div>

          <div
            style={{
              height: '60vh',
              overflow: 'clip',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Card
              title={
                <div style={{ fontSize: 16 }}>🤖 NextGIS Toolbox Expert</div>
              }
              bordered={false}
              style={{ width: 300, height: '240px' }}
              styles={{
                header: {
                  backgroundColor: 'rgb(245, 248, 255)',
                  fontFamily: 'Segoe UI Semibold',
                  fontSize: 24,
                  fontWeight: 600,
                },
                body: {
                  fontSize: 14,
                  padding: '10px',
                },
              }}
            >
              <p
                style={{
                  whiteSpace: 'pre-line',
                  fontWeight: 600,
                  fontFamily: 'Segoe UI Semibold',
                }}
              >
                {t('main.ai1')}
              </p>
              <p
                style={{
                  whiteSpace: 'pre-line',
                  fontFamily: 'Segoe UI',
                }}
              >
                {t('main.ai2')}
              </p>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  gap: '8px',
                }}
              >
                <a href={serverApi.getLoginRedirectUrl()}>
                  <Button type="primary">Log in</Button>
                </a>
                <a href={serverApi.getLoginRedirectUrl()}>
                  <Button type="primary">Sign up</Button>{' '}
                </a>
              </div>
            </Card>
          </div>
        </div>
      )}
    </div>
  );
};

export { AiChat };
