import { CaretUpOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React, { useEffect, useRef } from 'react';

import './ToolDocs.css';

const ToolDocs: React.FC<{ docs: string; upCallback?: () => void }> = ({
  docs,
  upCallback,
}) => {
  const docsRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const el = docsRef.current;
    if (el) el.innerHTML = docs;
  });

  return (
    <div
      style={{
        padding: '0px 6px',
        color: '#231f20',
        position: 'relative',
      }}
    >
      <div className="docs-container" ref={docsRef}></div>
      {upCallback && (
        <Button
          style={{
            position: 'absolute',
            bottom: '-5px',
            right: '10px',
            zIndex: 10000,
          }}
          shape="circle"
          icon={<CaretUpOutlined />}
          onClick={upCallback}
        ></Button>
      )}
    </div>
  );
};

export { ToolDocs };
